body {
  margin: 0;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Extralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-ExtralightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}
